import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, message, Space } from "antd";
import FloatLabel from "../FloatingLabel";
import { Button as BlueButton } from "../Einstellungen/components";
import { FaWhatsapp } from "react-icons/fa6";
import { IoShareOutline } from "react-icons/io5";
import PublicService from "../../service/PublicService";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/auth/selectors";

export default function ReferralSections() {
  const [jetztEmpfehlenModal, setJetztEmpfehlenModal] = useState(null);
  const [nachrichtZumVerschicken, setNachrichtZumVerschicken] = useState(null);
  const { user } = useSelector(selectUser);

  useEffect(() => {
    if (!user) return;
    PublicService.getAdminConfigure().then((response) => {
      setNachrichtZumVerschicken(
        response?.data?.config?.CMS?.affiliateBegleittext
          ?.replace?.(/{Partner Vorname}/g, user?.firstname)
          ?.replace?.(/{Partner Nachname}/g, user?.lastname)
          ?.replace?.(/{Link}/g, "Link Platzhalter")
      );
    });
  }, [user]);

  const handleWhatsapp = () => {
    let finalText = nachrichtZumVerschicken;

    window.open(
      `https://wa.me/?text=${encodeURIComponent(finalText)}`,
      "_blank"
    );
  };
  const handleEmpfehlen = () => {
    const link = "x";
    let finalText = "y";

    setJetztEmpfehlenModal({ finalText, link });
  };

  const handleShare = useCallback(() => {
    const link = jetztEmpfehlenModal.link;
    let finalText = nachrichtZumVerschicken;
    finalText += ` ${link}`;

    if (navigator.share) {
      navigator
        .share({
          title: "Empfehlung",
          text: finalText,
          url: link,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      // Fallback to email
      window.location.href = `mailto:?subject=Empfehlung&body=${encodeURIComponent(
        finalText
      )}`;
    }
  }, [jetztEmpfehlenModal, nachrichtZumVerschicken]);

  return (
    <div className="w-full max-w-[1200px] mx-auto px-4 mt-8 bg-[#eeeeee] rounded-[25px] border border-[#c7c7c7]">
      <div className="grid grid-cols-1 lg:grid-cols-[65%_35%] gap-8">
        {/* Left Column */}
        <div className="space-y-2 bg-[#ffffff] rounded-[25px] my-2 p-2">
          <h2 className="text-md font-bold text-center">
            Wem solltest Du den Link senden?
          </h2>

          {/* Target Groups */}
          <div className="grid grid-cols-4 smx:flex smx:flex-col grid-cols-[28%_28%_28%_16%] gap-2 !text-[12px]">
            {/* Financial Advisors */}
            <div className="bg-[#3B82F6] rounded-[24px] p-2 text-white text-center flex flex-col items-center justify-center font-semibold">
              <h3 className="font-bold mb-1">Finanzberater</h3>
              <ul className="space-y-1 ">
                <li>Versicherungsagentur</li>
                <li>Versicherungsmakler</li>
                <li>Vermögensberater</li>
              </ul>
            </div>

            {/* Structural Sales */}
            <div className="bg-[#60A5FA] rounded-[24px] p-2 my-2 smx:my-0 text-white text-center flex flex-col items-center justify-center font-semibold">
              <h3 className="font-bold mb-1">Strukturvertriebe</h3>
              <ul className="space-y-1 ">
                <li>PV Anlagen</li>
                <li>Immobilienfinanzierer</li>
                <li>Strom & Gas</li>
              </ul>
            </div>

            {/* Others */}
            <div className="bg-[#93C5FD] rounded-[24px] p-2 my-4 smx:my-0 text-white text-center flex flex-col items-center justify-center font-semibold">
              <h3 className="font-bold mb-1">sonstige</h3>
              <p className="">Direktvertriebe</p>
            </div>

            <div className="flex flex-col items-center justify-center mr-2 mx-[-20px] smx:hidden">
              <img
                src="/images/right-arrows.png"
                style={{ height: 50, width: 100 }}
              />
            </div>
          </div>

          {/* Info Boxes */}
          <div className="grid grid-cols-3 gap-4 smx:flex smx:flex-col smx:gap-1">
            {/* Bonus Box */}
            <div className="bg-gray-100 rounded-[24px] p-4 bg-[#eeeeee]">
              <div className="flex justify-start items-center gap-2 mb-3">
                <img
                  src="/images/faq-f.png"
                  className="object-cover m-[-5px]"
                  style={{ height: 50, width: 40 }}
                />
                <h3 className="font-bold">Prämie erhalten</h3>
              </div>
              <p className="text-[10px] text-center text-gray-600">
                Deinen Fortschritt kannst Du oben in der Prämienübersicht
                verfolgen. Sobald Du einen Meilenstein erreicht hast, erscheint
                ein Button über den du die Prämie einlösen kannst.
              </p>
            </div>

            {/* Double Recommendation Box */}
            <div className="bg-gray-100 rounded-[24px] p-4 bg-[#eeeeee] my-4 smx:my-0">
              <div className="flex justify-start items-center gap-2 mb-3">
                <img
                  src="/images/faq-a.png"
                  className="object-cover m-[-5px]"
                  style={{ height: 50, width: 40 }}
                />
                <h3 className="font-bold">doppelte Empfehlung</h3>
              </div>
              <p className="text-[10px] text-center text-gray-600">
                Sofern 2 Nutzer die selbe Empfehlung aussprechen, wird diese
                demjenigen zugeordnet, der die letzten Empfehlung ausgesprochen
                hat.
              </p>
            </div>

            {/* Personal Link Box */}
            <div className="bg-gray-100 rounded-[24px] p-4 bg-[#eeeeee] my-8 smx:my-0">
              <div className="flex justify-start items-center gap-2 mb-3">
                <img
                  src="/images/faq-q.png"
                  className="object-cover m-[-10px]"
                  style={{ height: 60, width: 40 }}
                />
                <h3 className="font-bold">Persönlicher Link</h3>
              </div>
              <p className="text-[10px] text-center text-gray-600">
                Der Link ist dir persönlich auf Dich zugeordnet. Somit ist
                sicher gestellt, dass das System immer genau weiß von wem
                empfohlen wurde.
              </p>
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="space-y-2 my-2 lg:pr-8 flex flex-col justify-between">
          {/* Free Trial Box */}
          <div className="bg-[#ffffff] rounded-[25px] p-2">
            <h2 className="text-lg font-bold text-center mb-2">
              WAS DU VERSCHENKST!
            </h2>
            <div className="border-4 text-[12px] border-[#3B82F6] text-[#3B82F6] rounded-full p-2 text-center font-semibold">
              kostenlosen Empfehlungsratgeber & EinGuterTipp garantiert 14 Tage
              kostenlos testen
            </div>

            {/* Subscription Info */}
            <p className="text-center font-semibold mt-2">
              Sobald ein aktives Abo
              <span className="text-[#3B82F6] underline font-semibold mx-1 hover:underline">
                ab Tarif Standard
              </span>
              gebucht wird, gilt deine Empfehlung als erfolgreich.
            </p>

            {/* Recommend Button */}

            <div className="w-full flex justify-center mt-2">
              <button
                onClick={() => handleEmpfehlen()}
                className="w-full whitespace-nowrap px-4 py-2 bg-[#FFD814] hover:bg-[#f7ca00] !text-[#000000] animate-jump-in   justify-center items-center flex text-center     rounded-[10px] font-semibold"
              >
                Jetzt empfehlen
              </button>
            </div>
          </div>

          {/* Team Referral Section */}
          <div className="text-center mt-12 relative bg-[#ffffff] rounded-[25px] p-2">
            <p className="mb-4 text-[12px] font-semibold">
              Du möchtest ein größeres Team oder eine Organisation empfehlen?
            </p>

            {/* Decorative "oder" */}
            <span className="absolute right-[calc(50%-60px)] top-[-38px] text-[#3B82F6] italic transform -rotate-12">
              <img src="/images/oder.png" style={{ height: 75 }} />
            </span>

            {/* Arrows */}
            <div className="relative">
              {/* <img
                src="/placeholder.svg?height=40&width=40"
                alt="Arrow"
                width={40}
                height={40}
                className="absolute -left-8 top-1/2 transform -translate-y-1/2"
              />
              <img
                src="/placeholder.svg?height=40&width=40"
                alt="Arrow"
                width={40}
                height={40}
                className="absolute -right-8 top-1/2 transform -translate-y-1/2"
              /> */}

              {/* Book Appointment Button */}

              <Link
                to="/erstgesprach"
                className="w-full flex justify-center mt-2"
              >
                <button className="w-full !bg-[#3B82F6] hover:!bg-[#3B82F6] !text-white whitespace-nowrap px-4 py-2 justify-center items-center flex text-center     rounded-[10px] font-semibold">
                  Termin buchen
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Modal
        wrapClassName="standardRoot-modal"
        footer={[]}
        onCancel={() => setJetztEmpfehlenModal(null)}
        closeIcon={<></>}
        open={jetztEmpfehlenModal}
        title=""
        centered={true}
      >
        <div className="ml-1 mb-5 flex items-center justify-between gap-5 md:ml-0">
          <div className=" text-[20px] smx:text-[14px] font-semibold text-[#000000] ">
            Empfehlung verschicken
          </div>
          <img
            src="/images/img_cross_small_1.svg"
            alt="Close Icon"
            className="h-[32px] cursor-pointer"
            onClick={() => setJetztEmpfehlenModal(null)}
          />
        </div>

        {/* {JSON.stringify()} */}

        <FloatLabel
          label={"Typ"}
          unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
          className={" flex-grow"}
          value={"EinGuterTipp weiterempfehlen"}
          disabled
        >
          <div className="w-full flex justify-between items-center ">
            <input
              type="text"
              className="text-[#535353] text-[16px] border-[1px] border-[#d8d8d8] outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none"
              value={"EinGuterTipp weiterempfehlen"}
              disabled
            />
            <img
              src={"/images/logo.png"}
              alt="Arrow Up"
              className=" h-[24px] w-[20px] object-contain absolute right-3"
            />
          </div>
        </FloatLabel>

        <br />

        <FloatLabel
          label={"Dein Empfehlungslink"}
          unFocusClass="sm:!top-[15px] !top-[20px]  !text-[12px] text-ellipsis sm:!text-[16px] !font-semibold  !w-full "
          className={" flex-grow"}
          value={jetztEmpfehlenModal?.link}
          disabled
        >
          <div className="w-full flex justify-between items-center ">
            <input
              type="text"
              className="text-[#535353] text-[16px] border-[1px] border-[#d8d8d8] pr-10 outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none"
              value={jetztEmpfehlenModal?.link}
              disabled
            />

            <img
              src="/images/clone 1.svg"
              alt="Arrow Up"
              className="h-[24px] w-[20px] object-contain absolute right-3 cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(jetztEmpfehlenModal?.link);
                message.info("Kopiert");
              }}
            />
          </div>
        </FloatLabel>

        <br />
        <br />

        <FloatLabel
          label={"Deine Nachricht"}
          unFocusClass="sm:!top-[15px] !top-[20px]   text-ellipsis text-[16px] !font-semibold  !w-full "
          className={" flex-grow"}
          value={nachrichtZumVerschicken}
          onChange={(e) => setNachrichtZumVerschicken(e.target.value)}
        >
          <div className="w-full flex justify-between items-center ">
            <textarea
              rows={4}
              className="border-[1px] border-[#d8d8d8] outline-none rounded-[15px] p-[15px_12.5px] w-full !appearance-none !text-[16px]"
              value={nachrichtZumVerschicken}
              onChange={(e) => setNachrichtZumVerschicken(e.target.value)}
              onKeyDown={(e) => {
                if (e.code === "Enter" && e.ctrlKey) {
                  e.preventDefault();
                  handleWhatsapp();
                }
              }}
            />
          </div>
        </FloatLabel>

        <BlueButton
          shape="round"
          className="w-full ml-1 mt-[75px] rounded-[10px] px-[34px] md:ml-0 sm:px-5 smx:text-[14px]"
          onClick={handleWhatsapp}
        >
          <Space>
            <FaWhatsapp size={20} />
            Jetzt per WhatsApp versenden
          </Space>
        </BlueButton>

        <BlueButton
          shape="round"
          className="w-full ml-1 mt-[15px] rounded-[10px] px-[34px] md:ml-0 sm:px-5 smx:text-[14px]"
          onClick={handleShare}
        >
          <Space>
            <IoShareOutline size={20} />
            Jetzt anderweitig teilen
          </Space>
        </BlueButton>
      </Modal>
    </div>
  );
}
