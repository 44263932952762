import { InputNumber } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/auth/selectors";
import { Heading, Img } from "../Einstellungen/components";
import UserProfileSelection from "../PromoterSite/components/UserProfileSelection";
import WelcomeSection from "../PromoterSite/WelcomeSection";
import ReferralSection from "./ReferralSection";

export default function Empfehlen() {
  const { user } = useSelector(selectUser);
  const [anzahlEmpfehlungen, setAnzahlEmpfehlungen] = useState(1);

  return (
    <div className="mt-1.5 flex flex-col items-center self-stretch">
      <div className="flex flex-col gap-6 container-xs mdx:px-2">
        <WelcomeSection
          refreshPromoter={() => {}}
          user_id={user._id}
          promoter={{
            firstname: "PromoterVorname",
            lastname: "PromoterNachname",
            user_id: user,
            anzahlEmpfehlungen,
          }}
          isEGTBonus
          alternativeHeading={
            <>
              <Heading
                size="headings"
                as="h1"
                className="!text-[20px] font-semibold text-[#000000]-900 px-2.5"
              >
                Hallo {user?.firstname}! JETZT{" "}
                <img
                  src="/images/logo.png"
                  height={30}
                  style={{ height: 30 }}
                  className="inline pl-2"
                />{" "}
                EinGuterTipp empfehlen und selbst profitieren
              </Heading>
            </>
          }
          alternativeSubheading={
            <>
              <Heading
                size="headingxs"
                as="h2"
                className="!text-[16px] font-medium text-[#000000]-900 px-2.5"
              >
                <span>
                  Sichere dir tolle Prämien je{" "}
                  <span className="text-cyan-a400">nach Anzahl</span>{" "}
                  erfolgreicher Empfehlungen
                </span>
              </Heading>
            </>
          }
        />
        <ReferralSection />
      </div>
    </div>
  );
}
